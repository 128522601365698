import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { WorkShift, EntityWorkShift } from './workShift.interface';
import { OrganizationWorkedShift } from "./organizationWorkedShift";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class CalendarService {

  public constructor(private http: HttpClient) { }

  /**
   * Create new calendar
   *
   * @param shifts
   */
  public newCalendar(shifts: OrganizationWorkedShift): Observable<any> { 
    return this.http.post(environment.api_url + "/orgWorkedShifts", shifts); 
  };

  /**
   * Get organization calendar in a date range
   *
   * @param idOrganization
   */
  public getOrgCalendar(idOrganization: number, from: string, to: string): Observable<any> { 
    let queryParams = new HttpParams();
    queryParams = queryParams.set('from', from.toString());
    queryParams = queryParams.set('to', to.toString());
    return this.http.get(environment.api_url + '/orgWorkedShifts/organization/' + idOrganization, { params: queryParams });
  };

  /**
   * Get organization calendar in a date range
   *
   * @param idEntity
   */
  public getEntityCalendar(idEntity: number, from: string, to: string): Observable<any> { 
    let queryParams = new HttpParams();
    queryParams = queryParams.set('from', from.toString());
    queryParams = queryParams.set('to', to.toString());
    return this.http.get(environment.api_url + '/entityWorkedShifts/entity/' + idEntity, { params: queryParams }); 
  };

  /**
   * Update organization calendar (multiple orgWorkedShifts)
   *
   * @param {orgWorkedShifts: WorkShift[]} calendar
   */
  public updateOrganizationCalendar(calendar: { orgWorkedShifts: WorkShift[] }): Observable<any> { 
    return this.http.put(environment.api_url + '/orgWorkedShifts', calendar); 
  };

    /**
   * Update entity calendar (multiple entityWorkedShifts)
   *
   * @param {entityWorkedShifts: WorkShift[]} calendar
   */
  public updateEntityCalendar(calendar: { entityWorkedShifts: EntityWorkShift[] }): Observable<any> { 
    return this.http.put(environment.api_url + '/entityWorkedShifts', calendar); 
  };

  /**
   * Get organization holidays
   *
   * @param idOrganization
   */
  public getOrgHolidays(idOrganization: number): Observable<any> { 
    return this.http.get(environment.api_url + '/orgHolidays/organization/' + idOrganization);
  };
 
  /**
   * Create new holiday
   * @param holiday 
   * @returns 
   */
  public addHoliday(holiday: { idOrganization: number, date: string }): Observable<any> { 
    return this.http.post(environment.api_url + '/orgHoliday', holiday);
  };
 
  /**
   * Delete holiday
   * @param idOrganization 
   * @param date 
   * @returns 
   */
  public removeHoliday (idOrganization: number, date: string): Observable<any> { 
    return this.http.delete(environment.api_url + '/orgHoliday/organization/' + idOrganization + '/date/' + date);
  }
 
  /**
   * get entity calendar
   * @param idEntity 
   * @returns 
   */
  public getEntityWorkedCalendar(idEntity: number): Observable<any> { 
    return this.http.get(environment.api_url + '/entityWorkedCalendar/entity/' + idEntity);
  };
 
  /**
   * update entity calendar
   * @param calendar 
   * @returns 
   */
  public updateEntityWorkedCalendar(calendar: { entityWorkedShifts: { idEntityWorkedShift: number, date: string, worked: boolean }[] }): Observable<any> { 
    return this.http.put(environment.api_url + '/entityWorkedCalendar', calendar);
  };
 
  /**
   * create entity calendar
   * @param shifts 
   * @returns 
   */
  public newEntityCalendar(shifts: { idEntity: number, shifts: { idShift: number, week: boolean[] }[] }): Observable<any> { 
    return this.http.post(environment.api_url + '/entityWorkedShifts', shifts);
  };
 
  /**
   * create special day shift worked
   * @param especialDayWorkedShifts 
   * @returns 
   */
  public updateSpecialDayShiftWorked(especialDayWorkedShifts: { idEntity: number, idShift: number, date: string, worked: boolean }[]): Observable<any> {
    return this.http.post(environment.api_url + '/specialDayShiftsWorked', especialDayWorkedShifts);
  };
 
  /**
   * get special day shifts worked
   * @param idEntity
   * @returns 
   */
  public getSpecialDayShiftsWorked(idEntity: number): Observable<{count: number, rows: any[]}> {
    return this.http.get<{count: number, rows: any[]}>(environment.api_url + '/specialDayShiftsWorked/' + idEntity);
  };

  // get array of dates of the selected month-year
  public getDaysInMonth (month, year): Date[] {
    return (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1)
  }

  // get string from date
  public getDateString(date: Date): string {
    return date.getFullYear()
    +"-"
    +("00"+(date.getMonth()+1)).slice(-2)
    +"-"
    +("00"+date.getDate()).slice(-2);
  }

  // get string from time
  public getShiftString(date: Date): string {
    return ("00"+date.getHours()).slice(-2)
    +":"
    +("00"+date.getMinutes()).slice(-2)
    +":"
    +("00"+date.getSeconds()).slice(-2);
  }

}
