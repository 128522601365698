// let SERVER_URL          = process.env.MANAGER_TESTING_URL ||  'http://localhost:3000';
// let API_URL      = process.env.MANAGER_API_TESTING_URL || 'http://localhost:3000/api/v1';

export const environment = {
  production: false,
  sentry: false,
  app_url: 'https://app.develop.vixion360.com',
  server_url: 'https://manager.develop.vixion360.com',  //ORACLE
  api_url: 'https://api.develop.vixion360.com/api/v1',  //ORACLE
  header_color: '#1a633e', // Dark green
  card_header_color: '#40a279' // Green
};
