import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard }       from './../auth-guard.service';
import { MainComponent }   from './main.component';

import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';

const mainRoutes: Routes = [
  { path: '', component: MainComponent,
    children: [
    {
      path: 'accounts',
      loadChildren: 'app/main/accounts/accounts.module#AccountsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'greasingTypes',
      loadChildren: 'app/main/greasingTypes/greasingTypes.module#GreasingTypesModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'axes',
      loadChildren: 'app/main/axes/axes.module#AxesModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'thresholds',
      loadChildren: 'app/main/thresholds/thresholds.module#ThresholdsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'entities',
      loadChildren: 'app/main/entities/entities.module#EntitiesModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'fleet',
      loadChildren: 'app/main/entities-control/entities-control.module#EntitiesControlModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'machine-availability',
      loadChildren: 'app/main/machine-availability/machine-availability.module#MachineAvailabilityModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'entityModels',
      loadChildren: 'app/main/entityModels/entityModels.module#EntityModelsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'roles',
      loadChildren: 'app/main/roles/roles.module#RolesModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'permissions',
      loadChildren: 'app/main/permissions/permissions.module#PermissionsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'organizations',
      loadChildren: 'app/main/organizations/organizations.module#OrganizationsModule',
      canLoad: [AuthGuard]
    },
    { path: 'settings',
      loadChildren: 'app/main/settings/settings.module#SettingsModule',
      canLoad: [AuthGuard]
    },
    { path: 'operatingModes',
      loadChildren: 'app/main/operatingModes/operatingModes.module#OperatingModesModule',
      canLoad: [AuthGuard]
    },
    { path: 'apps',
      loadChildren: 'app/main/apps/apps.module#AppsModule',
      canLoad: [AuthGuard]
    },
    { path: 'dataloggerHardwares',
      loadChildren: 'app/main/dataloggerHardwares/dataloggerHardwares.module#DataloggerHardwaresModule',
      canLoad: [AuthGuard]
    },
    { path: 'dataloggerHardwareModels',
      loadChildren: 'app/main/dataloggerHardwareModels/dataloggerHardwareModels.module#DataloggerHardwareModelsModule',
      canLoad: [AuthGuard]
    },
    { path: 'dataloggerSoftwares',
      loadChildren: 'app/main/dataloggerSoftwares/dataloggerSoftwares.module#DataloggerSoftwaresModule',
      canLoad: [AuthGuard]
    },
    { path: 'datasources',
      loadChildren: 'app/main/datasources/datasources.module#DatasourcesModule',
      canLoad: [AuthGuard]
    },
    { path: 'shifts',
      loadChildren: 'app/main/shifts/shifts.module#ShiftsModule',
      canLoad: [AuthGuard]
    },
    { path: 'datasourceModels',
      loadChildren: 'app/main/datasourceModels/datasourceModels.module#DatasourceModelsModule',
      canLoad: [AuthGuard]
    },
    { path: 'masterVariables',
      loadChildren: 'app/main/masterVariables/masterVariables.module#MasterVariablesModule',
      canLoad: [AuthGuard]
    },
    { path: 'mapVariables',
    loadChildren: 'app/main/mapVariables/mapVariables.module#MapVariablesModule',
      canLoad: [AuthGuard]
    },
    { path: 'bbjVariables',
      loadChildren: 'app/main/bbjVariables/bbjVariables.module#BBJVariablesModule',
      canLoad: [AuthGuard]
    },
    { path: 'masterSettings',
      loadChildren: 'app/main/masterSettings/masterSettings.module#MasterSettingsModule',
      canLoad: [AuthGuard]
    },
    { path: 'variableTypes',
      loadChildren: 'app/main/variableTypes/variableTypes.module#VariableTypesModule',
      canLoad: [AuthGuard]
    },
    { path: 'PMCSelectors',
      loadChildren: 'app/main/PMCSelectors/PMCSelectors.module#PMCSelectorsModule',
      canLoad: [AuthGuard]
    },
    { path: 'licenses',
      loadChildren: 'app/main/licenses/licenses.module#LicensesModule',
      canLoad: [AuthGuard],
    },
    { path: 'swagger',
      loadChildren: 'app/main/swagger/swagger.module#SwaggerModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'masterAddons',
      loadChildren: 'app/main/masterAddons/masterAddons.module#MasterAddonsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'addonModels',
      loadChildren: 'app/main/addonModels/addonModels.module#AddonModelsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'addons',
      loadChildren: 'app/main/addons/addons.module#AddonsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'aggregatedSignals',
      loadChildren: 'app/main/aggregatedSignals/aggregatedSignals.module#AggregatedSignalsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'notificationRules',
      loadChildren: 'app/main/notificationRules/notificationRules.module#NotificationRulesModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'reports',
      loadChildren: 'app/main/reports/reports.module#ReportsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'geometricReports',
      loadChildren: 'app/main/geometricReports/geometricReports.module#GeometricReportsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'hydraulicReports',
      loadChildren: 'app/main/hydraulicReports/hydraulicReports.module#HydraulicReportsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'heads',
      loadChildren: 'app/main/headReports/headReports.module#HeadReportsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'thermals',
      loadChildren: 'app/main/thermalReports/thermalReports.module#ThermalReportsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'eulas',
      loadChildren: 'app/main/eulas/eulas.module#EULAsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'eula',
      loadChildren: 'app/main/eula/eula.module#EULAModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'alarms',
      loadChildren: 'app/main/alarms/alarms.module#AlarmsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'incidentCauses',
      loadChildren: 'app/main/incidentCauses/incidentCauses.module#IncidentCausesModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'widgets',
      loadChildren: 'app/main/widgets/widgets.module#WidgetsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'widgetGroups',
      loadChildren: 'app/main/widgetGroups/widgetGroups.module#WidgetGroupsModule',
      canLoad: [AuthGuard]
    },
    {
      path: 'views',
      loadChildren: 'app/main/views/views.module#ViewsModule',
      canLoad: [AuthGuard]
    },
    { path: '',
      redirectTo: '/accounts',
      pathMatch: 'full',
      canLoad: [AuthGuard]
    }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(mainRoutes),
    Angulartics2RouterlessModule.forRoot(),
  ],
  exports: [RouterModule]
})

export class MainRoutingModule { }
